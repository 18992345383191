import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
//import { createClient } from "@supabase/supabase-js";
import axios from "axios";
function ContactUs() {
 // const supabaseUrl = "https://fqpddijztomvuirlxzng.supabase.co";
  //const supabaseKey =
  //  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZxcGRkaWp6dG9tdnVpcmx4em5nIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIyMzAxOTcsImV4cCI6MjAzNzgwNjE5N30.pOxf-9XDuAFuziBDuQoLHeR4iwoOQvAis4uLxzFaabE"; //process.env.SUPABASE_KEY
 // const supabase = createClient(supabaseUrl, supabaseKey);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);
  //console.log(supabase, "supabase")
  function isValidEmail(email) {
    // Basic email validation regex
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [Phonenumber, setPhonenumber] = useState("");
  const [Email, setEmail] = useState("");
  const [msg, setmsg] = useState("");
  const [UserName, setUserName] = useState("");
  const [msgsubject, setmsgsubject] = useState("");

  // async function submitCustmDataEmail() {
  //   // Validate required fields
  //   if (
  //     UserName === "" ||
  //     Phonenumber === "" ||
  //     Email === "" ||
  //     msg === "" ||
  //     msgsubject === ""
  //   ) {
  //     alert("Please fill out all required fields.");
  //     return;
  //   }
  //   // Validate email format (basic check)
  //   else if (!isValidEmail(Email)) {
  //     alert("Please enter a valid email address.");
  //     return;
  //   } else {
  //     const { data, error } = await supabase
  //       .from("Contact-us")
  //       .insert({
  //         ClientId: "30223f5c-4364-4cb3-b1e8-91854923b2ba",
  //         Query: msg,
  //         Name: UserName,
  //         Email: Email,
  //         Mobile: Phonenumber,
  //         Subject: msgsubject,
  //       })
  //       .select("ID");

  //     if (error != null) {
  //       alert(error.details);
  //       return;
  //     }
  //     if (data) {
  //       alert(
  //         "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
  //           data[0].ID
  //       );
  //       window.scrollTo(0, 0);
  //       window.location.reload();
  //     }
  //   }
  // }

  const submitCustmDataEmailNew = async () => {
    if (
      UserName === "" ||
      Phonenumber === "" ||
      Email === "" ||
      msg === "" ||
      msgsubject === ""
    ) {
      alert("Please fill out all required fields.");
      return;
    }
    // Validate email format (basic check)
    else if (!isValidEmail(Email)) {
      alert("Please enter a valid email address.");
      return;
    } else {
      const data = {
        ClientId: "30223f5c-4364-4cb3-b1e8-91854923b2ba",
        Query: msg,
        Name: UserName,
        Email: Email,
        Mobile: Phonenumber,
        Subject: msgsubject
      };

      const headers = {
        "Content-Type": "application/json",
        "clientid": "30223f5c-4364-4cb3-b1e8-91854923b2ba",
        "page-name": "Contact_Us",
        //"SendToEmail": "Chandrakant111420@gmail.com",
        "EmailSubject": "Contact Form Submission",
      };
      try {
        const response = await fetch(
          "https://crmleads.erpthemes.com/save-result",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const result = await response.json();
        if (result.data) {
          alert(
            "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
            result.data[0].ID
          );
          window.scrollTo(0, 0);
          window.location.reload();
        } else {
          console.log(result.error)
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error.message);
      }
    }
  };
  return (
    <>
      <div>
        <Header />{" "}
      </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div
              className="main-heading"
              style={{ marginBottom: 20, marginTop: 20 }}
            >
              <h2 style={{ fontSize: 28 }}>Contact Us</h2>
            </div>
            <div className="row">
              <div className="col-md-8 col-xs-12 col-sm-12  ">
                {/* <form id="contactForm" method="post" action="#"> */}
                <div className="row">
                  <div className="col-sm-6">
                    {/* Name */}
                    <div className="form-group">
                      <label>
                        Name <span className="required">* </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        id="name"
                        name="name"
                        className="form-control"
                        required=""
                        value={UserName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* End col-sm-6 */}
                  <div className="col-sm-6">
                    {/* Email */}
                    <div className="form-group">
                      <label htmlFor="email">
                        Email <span className="required">* </span>
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        id="email"
                        name="email"
                        className="form-control"
                        required=""
                        value={Email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End col-sm-6 */}
                <div className="row">
                  <div className="col-sm-12">
                    {/* Email */}
                    <div className="form-group">
                      <label htmlFor="email">
                        Phone<span className="required">* </span>
                      </label>
                      <input
                        type="tel"
                        minLength={10}
                        maxLength={10}
                        placeholder="Phone Number"
                        id="phone"
                        name="phone"
                        className="form-control"
                        required=""
                        value={Phonenumber}
                        onChange={(e) => {
                          setPhonenumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {/* Email */}
                    <div className="form-group">
                      <label>
                        Subject <span className="required">* </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Subject"
                        id="subject"
                        name="subject"
                        className="form-control"
                        required=""
                        value={msgsubject}
                        onChange={(e) => {
                          setmsgsubject(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End col-sm-12 */}
                {/* End col-sm-6 */}
                <div className="row">
                  <div className="col-sm-12">{/* Email */}</div>
                </div>
                {/* End col-sm-12 */}
                <div className="row">
                  <div className="col-sm-12">
                    {/* Comment */}
                    <div className="form-group">
                      <label>
                        Message <span className="required">* </span>
                      </label>
                      <textarea
                        placeholder="Message..."
                        id="message"
                        name="message"
                        className="form-control"
                        rows={3}
                        required=""
                        defaultValue={""}
                        value={msg}
                        onChange={(e) => {
                          setmsg(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End col-sm-12 */}
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      id="yes"
                      onClick={submitCustmDataEmailNew}
                      className="btn btn-primary"
                    >
                      Send Message{" "}
                    </button>
                    <img
                      id="loader"
                      alt=""
                      src="images/loader.gif"
                      className="loader"
                    />
                  </div>
                </div>
                {/* End col-sm-6 */}
                {/* </form> */}
              </div>
              <div className="col-md-4 col-xs-12 col-sm-12 margin-top-30">
                <div className="location-box">
                  {" "}
                  <a className="media-left pull-left" href="#">
                    {" "}
                    <i className=" icon-map" />
                  </a>
                  <div className="media-body">
                    {" "}
                    <strong>OUR OFFICE LOCATION </strong>
                    <p>
                      Office No 204, Deenar Bhawan 44, Nehru place New
                      Delhi- 110019
                    </p>
                  </div>
                </div>
                <div className="location-box">
                  {" "}
                  <a className="media-left pull-left" href="#">
                    {" "}
                    <i className=" icon-envelope" />
                  </a>
                  <div className="media-body">
                    {" "}
                    <strong>OUR CONTACT E-MAIL </strong>
                    <p>kanika@tanejacapital.com</p>
                  </div>
                </div>
                <div className="location-box">
                  {" "}
                  <a className="media-left pull-left" href="#">
                    {" "}
                    <i className="icon-phone" />
                  </a>
                  <div className="media-body">
                    {" "}
                    <strong>Call us 24/7 </strong>
                    <p> +91-9910921339 | 011 49326230</p>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
          {/* end container */}
        </section>
      </div>
      <div>
        <Footer />{" "}
      </div>
    </>
  );
}

export default ContactUs;
