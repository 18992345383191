import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from "./Components/Home";
import Corephilosophies from './Components/Corephilosophies';
import Pms from './Components/Pms';
import MutualFund from './Components/MutualFund';
import Trading from './Components/Trading';
import WealthManagement from './Components/WealthManagement';
import AboutUS from './Components/AboutUS';
import Career from './Components/Career';
import ContactUs from './Components/ContactUs';
import TermsAndConditions from './Components/TermsAndConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <BrowserRouter>
  
  <Routes>
    
      <Route path="/" element={<Home />} /> 
      <Route path="/core-philosophies" element={<Corephilosophies />} /> 
      <Route path="/pms" element={<Pms />} /> 
      <Route path="/mutual-fund" element={<MutualFund />} /> 
      <Route path="/trading" element={<Trading />} /> 
      <Route path="/wealth-management" element={<WealthManagement />} /> 
      <Route path="/about-us" element={<AboutUS />} /> 
      <Route path="/career" element={<Career />} /> 
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> 
      <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
      
      
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
