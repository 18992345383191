import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function PrivacyPolicy() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading">
              <h2 style={{ fontSize: 28 }}>Privacy Policy</h2>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We respect your privacy and are committed to protect the personal
                information that you share with us. In order to endow you with our
                uninterrupted use of services, we collect, and disclose, information
                about you. To enhance better protection of your privacy we provide this
                notice explaining our information practices and the choices you can make
                about the way your information is collected and used.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                If you have any questions or concerns regarding this privacy policy, you
                should contact our Customer Support Desk immediately.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We shall use the personal information to improve our services to you and
                to keep you updated about our new product or information that may be of
                interest to you. The information collected from you would be used in the
                right spirit and context in which it is intended to be used.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                OVERVIEW
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We commit to respecting your online privacy data. We further recognize
                your need for appropriate protection and management of any personally
                identifiable information ("Personal Information") you share with us.
                Information that is considered personal about you includes, but is not
                limited to, your name, address, email address or other contact
                information, your bank account / Credit / Debit Card details, your
                financial information, investment experience, investment objectives,
                your financial situation, KYC norms, etc. This privacy policy also
                applies to data we collect from users who are not registered as members
                of this site, but browse, access or view the site, for informational
                purposes or otherwise.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                1. NOTIFICATION OF MODIFICATIONS AND CHANGES TO THE TERMS &amp;
                CONDITIONS AND PRIVACY POLICY
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We reserve the right to change the Privacy Policy from time to time as
                deem fit, without any intimation to you, and your continued use of the
                site will signify your acceptance of any amendment to these terms.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                You are therefore advised to re-read the Privacy policy on a regular
                basis. Should it be that you do not accept any of the modifications or
                amendments, you should terminate your use of this website and the
                products and services offered through it with immediate effect.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                2. INFORMATION WE COLLECT
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                {" "}
                In order to use this website, you may be required to register yourself
                by providing the following information which includes, but is not
                limited to:
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Name <br />
                E-mail address; <br />
                Internet Protocol address; <br />
                Pincode; <br />
                Age and Gender; <br />
                Preferences and settings for the home page and other pages on our site,
                preferences for time-zone, language, etc; <br />
                Your browsing pattern, search history, links clicked, services viewed,
                features used to view services, the duration of such views, etc.; <br />
                KYC norms; <br />
                Financial history and situation; <br />
                Risk appetite, Investment objectives and duration of investments; <br />
                Income details; <br />
                Existing investments / assets; <br />
                Liability / borrowing details.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                In addition to the above, we may conduct surveys, polls and other
                information gathering tools on our website to get your feedback about
                various things such as your experience with our website, the products
                and services offered, and so forth. We also collect information on
                browsing behavior, pages viewed, and other information required for
                analytics purposes.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                By providing the Personal Information of another person, you imply that
                you have all the necessary authority and/or have obtained all necessary
                consents from such person to enable us to collect, use and disclose his
                or her Personal Information as set forth in this Privacy Policy.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                COOKIES
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We may use data collection devices such as "cookies" on certain pages of
                our Websites. "Cookies" are small files sited on your hard drive that
                assist us in providing customized services. We may also offer certain
                features that are only available through the use of a "cookie". Cookies
                help us provide information, which is targeted to your interests.
                Cookies may be used whether you register with us or not.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                EXTERNAL LINKS ON WEBSITE
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                The Website may include hyperlinks to other web sites or content or
                resources. We have no control over any websites or resources, which are
                provided by companies or persons other than us.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                You acknowledge and agree that we are not responsible for the
                availability of any such external sites or resources, and do not endorse
                any advertising, products or other materials on or available from such
                websites or resources.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                You acknowledge and agree that we are not liable for any loss or damage
                which may be incurred by you as a result of the availability of those
                external sites or resources, or as a result of any reliance placed by
                you on the completeness, accuracy or existence of any advertising,
                products or other materials on, or available from, such websites or
                resources. These third-party service providers and third-party sites may
                have their own privacy policies governing the storage and retention of
                your personal information that you may be subject to. We recommend that
                when you enter a Third-Party website, you review the Third Party
                website's privacy policy as it relates to safeguarding your personal
                information collected by them. We use third-party advertising companies
                to serve ads when you visit the Website.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                CONFIDENTIALITY
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                You further acknowledge that the website may contain information which
                is designated confidential by us and that you shall not disclose such
                information without our prior written consent.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Your information is regarded as confidential and therefore will not be
                divulged to any third party except as set forth in this and any other
                term of use applicable to our website.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                OUR DISCLOSURE OF YOUR INFORMATION
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                From time to time, we may disclose your Personal Information as follows:
                <br />
                <br />
                a. We may collaborate with other companies to offer you additional
                Products or Services, for example, through promotions. We will share
                Personal Information that is necessary for these other companies to
                provide the Products or Services that you have requested. This policy
                will not cover the use of your Personal Information by these other
                companies. We encourage you to read a company's privacy policy before
                requesting any of its products or services.
                <br />
                <br />
                b. We may also disclose your information to our affiliates and
                non-affiliated business partners for their use both on our behalf and
                for their own business purposes. For example, our affiliates and
                business partners may use such information to send you information about
                their products, services, other information and materials that may be of
                interest to you.
                <br />
                <br />
                c. To protect ourselves against liability or prevent fraudulent
                activity; or where it is necessary to permit us to pursue available
                remedies or limit any damages that we may sustain.
                <br />
                <br />
                d. We cooperate with law enforcement inquiries, as well as other third
                parties to enforce laws, such as: intellectual property rights, fraud
                and other rights. We can (and you authorize us to) disclose any
                information about you to law enforcement and other government officials
                as we, in our sole discretion, believe necessary or appropriate, in
                connection with an investigation of fraud, intellectual property
                infringements, or other activity that is illegal or may expose us or you
                to legal liability.
                <br />
                <br />
                e. We may use and share the User information with reliable and reputed
                third-party payment gateway with whom we are associated in order to
                ensure swift and comfortable payment mechanism for the User. The
                third-party payment gateway and other payment transaction processors,
                have their own privacy policies in respect to the information we are
                required to provide to them for your purchase-related transactions. For
                these providers, we recommend that you read their privacy policies so
                you can understand the manner in which your personal information and
                your credit/debit card details will be handled by these providers. Once
                you leave our website or are redirected to a third-party website or
                application, you are no longer governed by this Privacy Policy or our
                website's Terms of Service.
                <br />
                <br />
                f. Due to the existing regulatory environment, we cannot ensure that all
                of your personally identifiable information will never be disclosed in
                ways not otherwise described in this Privacy Policy. By way of example
                (without limiting and foregoing), we may be forced to disclose
                information to the government, law enforcement agencies or third
                parties. Under certain circumstances, third parties may unlawfully
                intercept or access transmissions or members may abuse or misuse your
                information that they collect from our Websites. Therefore, we do not
                promise, and you should not expect, that your personally identifiable
                information or private communications would always remain private.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                CONTROL OF YOUR PASSWORD
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                You are entirely responsible for maintaining the confidentiality of your
                password. It is important that you protect it against unauthorized
                access of your account and information by choosing your password
                carefully, and keeping your password and computer secure by signing out
                after using our services.
                <br />
                <br />
                You agree not to use the account, username, email address or password of
                another Member at any time or to disclose your password to any third
                party. If you choose to share this information with third parties to
                provide you additional services, you are responsible for all actions
                taken with your login information and password and therefore should
                review each third party's privacy policy. You are responsible for all
                actions taken with your login information and password. If you lose
                control of your password, you may lose substantial control over your
                personally identifiable information and may be subject to legally
                binding actions taken on your behalf. Therefore, if your password has
                been compromised for any reason, you should immediately change your
                password. You agree to notify us immediately if you suspect any
                consistent unauthorized use of your account or access to your password
                even after changing it.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                OTHER INFORMATION COLLECTORS
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Except as otherwise expressly included in this Privacy Policy, this
                document only addresses the use and disclosure of information we collect
                from you. To the extent that you disclose your information to other
                parties, whether they are on our Websites or on other sites throughout
                the Internet, different rules may apply to their use or disclosure of
                the information you disclose to them. To the extent that we use third
                party advertisers, they adhere to their own privacy policies. Since we
                do not control the privacy policies of the third parties, you are
                subject to ask questions before you disclose your personal information
                to others
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                DISCLAIMER
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We cannot ensure that all of your private communications and other
                personal information (including sensitive information like credit card
                information and bank account number) will never be disclosed in ways not
                otherwise described in this Privacy Policy. Therefore, although we are
                committed to protecting your privacy, we do not promise, and you should
                not expect, that your personal information will always remain private.
                As a user of the website, you understand and agree that you assume all
                responsibility and risk for your use of the website, the internet
                generally, and the documents you post or access and for your conduct on
                and off the website.
              </p>
            </div>
          </div>
          {/* end container */}
        </section>


      </div >
      <div><Footer /> </div>
    </>
  );
}

export default PrivacyPolicy;
