import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function Pms() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading">
              <strong style={{ fontSize: 20, color: "#000b12", fontWeight: "normal" }}>
                Taneja Capital
              </strong>
              <h2 style={{ fontSize: 28 }}>Portfolio Management Services (PMS)</h2>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                A portfolio management service (PMS) is a customised investment solution
                offered to a client with a higher entry investment stipulated. Unlike a
                mutual fund that creates a standard portfolio for a fund and then issues
                units to customers, the PMS is more customised to the unique return
                requirements and risk capacity of the client. PMS schemes in India are
                either discretionary or non-discretionary. While a non-discretionary PMS
                invests with the approval of the client, a discretionary PMS designs its
                own unique portfolio based on the market conditions and the wealth
                creation potential.
              </p>
              <h4
                style={{
                  fontSize: 28,
                  margin: 5,
                  fontWeight: 600,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#3498db",
                  textTransform: "capitalize"
                }}
              >
                PMS Advising At Taneja Capital
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Taneja Capital has adopted a discretionary approach to its PMS products.
                The focus of the Taneja Capital is on return enhancement as well as on
                appropriate risk management. Our approach to the PMS is based on the 3
                key factors.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Long term approach
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                The essential philosophy of PMS Advising at Taneja Capital is to adopt a
                long term approach to equities. While equities as an asset class are the
                best long term wealth creators, selection of equities is critical to
                manage risk. That is where our PMS model based on opportunities,
                financials and potential disruption comes in handy.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Absolute returns plus Alpha
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                At Taneja Capital we believe that outperformance of the index (Alpha) is
                just one side of the story. The focus of our PMS is to handle asset
                allocation in such a way as to generate absolute positive returns for
                clients on a consistent basis, notwithstanding market conditions.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Capital allocation and momentum
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                In a complex market like India, the need of the hour is to combine a
                value approach with a momentum driven approach. Our approach combines
                value exploration in equities along with identifying the key momentum
                triggers to maximize your returns over a period of time.
              </p>
            </div>
          </div>
          {/* end container */}
        </section>
      </div>
      <div><Footer /> </div>
    </>
  );
}

export default Pms;
