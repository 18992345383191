import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function Corephilosophies() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading">
              <strong style={{ fontSize: 20, color: "#000b12", fontWeight: "normal" }}>
                Investing
              </strong>
              <h2 style={{ fontSize: 28 }}>Core Values of Investing</h2>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Advisors have a fiduciary responsibility to its customers to protect and
                generate superior return at a given risk compared to the no brainer
                relevant benchmark and the peers. But, data suggest that majority of the
                advisors and across times and across countries have failed to do so,
                either because of insufficient capabilities or lack of discipline. We,
                at Taneja Capital, have consistently been No 1 or amongst the top
                performers, largely because of a disciplined, unbiased, process and data
                driven multi asset, multi strategy framework.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Superior and Consistent Risk Adjusted Returns
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Not only do we focus on returns, we also focus on the quality of
                returns. Our objective is to maximize returns per unit of risk for the
                investor.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Research, Data &amp; Analytics Driven
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We have created the best data-driven buy-side research teams in the
                country, with capabilities spread across asset classes and investment
                strategies. We use our extensive in-house data framework &amp;
                infrastructure built over the past decade for both domestic and
                international securities to support our research process. Our
                proprietary analytical engine with intelligent strategy development and
                testing capabilities provides quantitative as well as qualitative
                support in selecting appropriate investment strategies.
              </p>
              {/*<h4 style="font-size:21px; margin:20px 0px 5px; color:#3498db; text-transform:capitalize; font-family: 'Source Sans Pro', sans-serif;">Sole Investments Advising Focus</h4>
                <p style="text-align:left;  margin:0 0 20px 0; width:100%" title="Top CA firm in delhi-ncr">We believe Investments Advising is a serious business and it merits our singular focus. At Taneja Capital our only business is to give unbiased advice for our clients. We are not in other areas of finance such as broking, consulting and distribution.</p>*/}
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Clients First
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We not only believe in providing the best investment advisory services
                to our clients but also believe in keeping them fully engaged and
                informed. We strive to create awareness about different
                assets,investment strategies and the risk profile of the client's
                portfolio. We believe that clients demand both quantity and quality in
                investment returns.
              </p>
            </div>
          </div>
          {/* end container */}
        </section>
      </div>
      <div><Footer /> </div>
    </>
  );
}

export default Corephilosophies;
