import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
function Header() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <header className="header-area">
    {/* Logo Bar */}
    <div className="logo-bar">
      <div className="container clearfix">
        {/* Logo */}
        <div className="logo" style={{ padding: "5px 0" }}>
          <a href="/">
            <img
              src="assets/images/logo.png"
              className="img-responsive"
              alt="Taneja Capital"
              title="Top CA firm in delhi-ncr"
            />
          </a>
        </div>
        {/*Info Outer*/}
        <div className="information-content">
          <div className="navigation-2">
            {/* navigation-start */}
            <nav className="navbar navbar-default ">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#main-navigation"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation </span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              </div>
              <div className="collapse navbar-collapse" id="main-navigation">
                <ul className="nav navbar-nav">
                  <li>
                  <Link to="/" data-animations="fadeInUp">Home</Link>
                    {/* <a href="index.html" data-animations="fadeInUp">
                      Home
                    </a> */}
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle " data-hover="dropdown" data-toggle="dropdown" data-animations="fadeInUp"
                    >
                      Investing <span className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                      <Link to="/core-philosophies" data-animations="fadeInUp"> Core Values Of Investing</Link>
                        {/* <a
                          href="core-philosophies"
                          data-animations="fadeInUp"
                        >
                          Core Values Of Investing
                        </a> */}
                      </li>
                      <li>
                      <Link to="/pms" data-animations="fadeInUp"> PMS</Link>
                        {/* <a href="pms.html" data-animations="fadeInUp">
                          PMS
                        </a> */}
                      </li>
                      <li>
                      <Link to="/mutual-fund" data-animations="fadeInUp"> Mutual Fund</Link>
                        {/* <a href="mutual-fund.html" data-animations="fadeInUp">
                          Mutual Fund
                        </a> */}
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle " data-hover="dropdown"
                      data-toggle="dropdown" data-animations="fadeInUp" >
                      Broking <span className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                      <Link to="/trading" data-animations="fadeInUp"> Trading</Link>
                        {/* <a href="trading.html" data-animations="fadeInUp">
                        Trading
                        </a> */}
                      </li>
                    </ul>
                  </li>
                  <li>
                  <Link to="/wealth-management" data-animations="fadeInUp">  Wealth Mangement</Link>
                    {/* <a href="wealth-management.html" data-animations="fadeInUp">
                      Wealth Mangement
                    </a> */}
                  </li>
                  <li>
                  <Link to="/about-us" data-animations="fadeInUp"> About Us</Link>
                    {/* <a href="about-us.html" data-animations="fadeInUp">
                      About Us
                    </a> */}
                  </li>
                  <li>
                  <Link to="/career" data-animations="fadeInUp">Careers</Link>
                    {/* <a href="career.html" data-animations="fadeInUp">
                      Careers
                    </a> */}
                  </li>
                  <li>
                  <Link to="/contact-us" data-animations="fadeInUp">Contact Us</Link>
                    {/* <a href="contact-us.html" data-animations="fadeInUp">
                      Contact Us
                    </a> */}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
  );
}

export default Header;
