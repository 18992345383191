import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function Trading() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            <div className="main-heading">
              <h2 style={{ fontSize: 28 }}>
                Trading <br />
                First, beat the Myth
              </h2>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                I can make Money for myself.
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 5px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                If that be so, why don't we see name of any trader in the fortune 500 or
                may be fortune 5 lac capitalists.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                There is no evidence to prove that common traders can make money for
                themselves.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                My RM can make Money for me.
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 5px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                If RM or his company could do it, they would have done it for
                themselves. Why would he be working for a small salary?
              </p>
              <div className="col-md-6">
                <h4
                  style={{
                    fontSize: 21,
                    margin: "20px 0px 5px",
                    color: "#3498db",
                    textTransform: "capitalize",
                    fontFamily: '"Source Sans Pro", sans-serif'
                  }}
                >
                  My Money Management Strategy
                </h4>
                <ul className="fund" style={{ marginBottom: 40 }}>
                  <li title="Top CA firm in delhi-ncr">
                    The first objective should be not to loose money.
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Should focus on success ratio of more than 50% and profit factor of
                    &gt; 1.
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Should cut losses and let the profit run. People normally fail in
                    Loss management.
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    When in doubt stay out and relax. It is not a full time job.
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Don't wait for the best price; look for good price.
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Strong discipline. Review the strategy periodically but don't change
                    it only because its showing loss.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <img
                  src="/assets/images/trading.png"
                  alt="Taneja Capital"
                  title="Top CA firm in delhi-ncr"
                />
              </div>
              <br />
              <br />
              <br />
              <div className="clearfix" />
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Trading Platforms
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We Use platform which has access to multiple exchanges , multiple
                depository, very user friendly and easy to operate.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                My RM can make Money for me.
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Technical, Statistical, Fundamental Understanding of RM{" "}
              </p>
            </div>
          </div>
        </section>
      </div >
      <div><Footer /> </div>
    </>
  );
}
export default Trading;
