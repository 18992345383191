import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function TermsAndConditions() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading">
              <h2 style={{ fontSize: 28 }}>Terms &amp; Conditions</h2>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                In terms of Information Technology Act, 2000, this document is an
                electronic record. Being generated by a computer system it does not
                require any physical or digital signatures.
                <br />
                <br />
                Please read these Terms and Conditions of Use ("Terms") carefully. These
                Terms, along with the others found on our website, include our policy
                for acceptable use of website{" "}
                <a
                  href="http://tanejacapital.com/"
                  target="_blank"
                  style={{ color: "#005abe" }}
                >
                  www.Taneja Capital.com
                </a>
                , its content, the content posted on the forum, your rights, obligations
                and restrictions regarding your use of this Site. By accessing{" "}
                <a
                  href="http://tanejacapital.com/"
                  target="_blank"
                  style={{ color: "#005abe" }}
                >
                  www.Taneja Capital.com
                </a>
                , you accept, without limitation or qualification, all the Terms
                applicable to the website, as amended from time to time. You are only
                authorized to use{" "}
                <a
                  href="http://tanejacapital.com/"
                  target="_blank"
                  style={{ color: "#005abe" }}
                >
                  www.Taneja Capital.com
                </a>{" "}
                and its services if you agree to abide by all applicable laws and to
                these Terms. If you do not accept these Terms, you must discontinue use
                of{" "}
                <a
                  href="http://tanejacapital.com/"
                  target="_blank"
                  style={{ color: "#005abe" }}
                >
                  www.Taneja Capital.com
                </a>
                .<br />
                <br />
                Notwithstanding anything else to the contrary, it is hereby specified
                that product or service availed by you shall be subject to specific
                terms governing such transaction, over and above the terms set forth
                herein. <br />
                <br />
                We may modify these Terms from time to time and such modification will
                be effective upon posting on this Site. You agree to be bound to any
                changes to these Terms when you use this Site after any such
                modification is posted. It is important that you review these Terms
                regularly to ensure you are updated as to any changes made.
                <br />
                <br />
                We reserve the right, in our sole discretion, to terminate the access to
                the website and the related services or any portion thereof at any time,
                without notice.
                <br />
                <br />
                "We", "Us", "Our" "Company" shall mean{" "}
                <a
                  href="http://tanejacapital.com/"
                  target="_blank"
                  style={{ color: "#005abe" }}
                >
                  www.Taneja Capital.com
                </a>{" "}
                , a domain owned by Taneja Capital Group.
                <br />
                <br />
                "Terms", "Terms and conditions", "Policy", "T&amp;C" shall mean the
                entire Agreement and all parallel policies that you agree to in mutual
                agreement by using our Website. "You", "Your", "User" refers to the user
                of the Website. "User" in this context shall mean and refer to the legal
                / natural entity the Company has entered into an agreement with to
                facilitate and provide the User's access to Products &amp; Services and
                other content available on the Website. It is hereby specified that the
                term User shall also include legal / natural entities making payment for
                Products &amp; Services offline through modes of payment other than
                Internet banking or the payment gateways made available. "Content"
                refers to any text, image, graphic, video, audio or all forms of data
                which are made available on the Website.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                ADDITIONAL TERMS
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Additional Terms may apply when you use / purchase Products or Services
                on the website. You agree to abide by the terms and conditions imposed
                by us, including payment of all the amounts when due, and comply with
                all rules and restrictions regarding the Products or Services.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                AGE RESTRICTION
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Minors (as defined under the laws of their jurisdiction or residence)
                are not eligible to register for, use or Purchase the Products or
                Services available on the Site.
                <br />
                <br />
                Without limiting any other provisions of these Terms, you may not use
                this Site for any purpose that is unlawful or prohibited by these Terms
                and/or any applicable additional terms. Your access of this Site may be
                terminated immediately, in our sole discretion, with or without notice,
                if you fail to comply with any provision of these Terms and/or
                additional terms, or for any other reason, or no reason.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                USER ID AND PASSWORD
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Access to certain areas of the Site is available only to registered
                Members. To become a registered Member, you are required to provide
                certain information. You represent and warrant that all information
                which you supply to us about yourself and others is true and accurate.
                <br />
                <br />
                You are responsible for maintaining the secrecy of your User Name and
                Password. In addition, you will be financially accountable for all use
                of our Site by yourself and anyone using your password and login
                information. In the event you provide us with false and inaccurate
                details or we have reasonable reasons to believe you have done so, we
                hold the right to permanently suspend your account.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                COMMUNICATIONS
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                By using this website, it is deemed that you have consented to receiving
                calls, autodialed and/or pre-recorded message calls, SMS, from us or our
                vendors, at any time, on the telephone number / contact information that
                has been provided by you even if the contact number you have entered is
                on DND (Do not Disturb), for the use of this website or our services. We
                may send inquiry confirmation / intimation, booking confirmation,
                cancellation, payment confirmation, schedule change or any such other
                information relevant for the transaction, via SMS or by voice call on
                the contact number given by the User at the time of registration /
                reservation. We may also contact you to clarify your inquiry, seek
                additional details pertaining to it, etc. The use of this website is
                also your consent to receive SMSs from us and our vendors at any time,
                as we deem fit. This consent to be contacted is for purposes that
                include and are not limited to clarification calls and marketing and
                promotional calls.
                <br />
                <br />
                You may also be contacted by Service Providers with whom we have entered
                into a contract in furtherance of our rights, duties and obligations
                under this document, and all other policies followed by us. The sharing
                of the information provided by you is governed by the Privacy Policy.
                <br />
                <br />
                You hereby unconditionally consent that such communications via SMS and/
                or voice call is (a) upon your request and authorization, (b)
                'transactional' and not an 'unsolicited commercial communication' as per
                the guidelines of Telecom Regulation Authority of India (TRAI) and (c)
                in compliance with the relevant guidelines of TRAI or such other
                authority in India and abroad. We may send communication via SMS, Email
                &amp; Android app notification. The delivery of the SMS on national and
                international numbers is solely dependent on the vendor's infrastructure
                and the network of the receiver. We will not be responsible for delay in
                delivery or non-delivery of the SMS.
                <br />
                <br />
                You hereby agree and undertake to indemnify us against all types of
                losses and damages incurred by us due to any action taken by TRAI,
                Access Providers (as per TRAI regulations) or any other authority due to
                any erroneous complaint raised by you against us with respect to the
                intimations mentioned above or due to a wrong number or email id being
                provided by you for any reason whatsoever.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                CONTENT ON WEBSITE
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                The content of the pages of this website is for your general information
                and use only. It is subject to change without notice.
                <br />
                <br />
                Your use of any information on this website is entirely at your own
                risk, for which we shall not be liable. It shall be your own
                responsibility to ensure that any products, services or information
                available through this website meet your specific requirements. In no
                event shall we assume or have any responsibility or liability for any
                Content posted or for any claims, damages or losses resulting from use
                of Content and/or appearance of Content on the Website.
                <br />
                <br />
                The material on this Site is protected by Indian and international
                copyright, trademark, and other applicable laws. You may not modify,
                copy, reproduce, republish, upload, post, transmit, publicly display,
                prepare derivative works based on, or distribute in any way any material
                from the website without a license or other written permission obtained
                in advance from the owner of such Intellectual Property, including but
                not limited to text, audio, video, code and software.
                <br />
                <br />
                During your visit to this Site, however, you may download the material
                displayed, for personal use only, provided you retain all intellectual
                property legends contained in the materials, if any. We neither warrant
                nor represent that your use of materials displayed on this Site will not
                infringe rights of third parties not owned by us or affiliated with us.
                For the removal of doubt, it is clarified that unlimited or wholesale
                reproduction or copying of the content for commercial purposes and
                unwarranted modification of data and information within the content of
                the website is not permitted.
                <br />
                <br />
                We shall have the right to remove or edit any content that in our sole
                discretion violates, or is alleged to violate, any applicable law or the
                spirit of these Terms of Use. Notwithstanding this right, you remain
                solely responsible for the content of the materials you provide.
                <br />
                <br />
                You are strictly prohibited from posting or transmitting any unlawful,
                threatening, libellous, defamatory, obscene, scandalous, inflammatory,
                pornographic, or profane material that could constitute or encourage
                conduct that would be considered a criminal offense, give rise to civil
                liability, or otherwise violate any law. You are a restricted user of
                this website and the services offered by us. <br />
                <br />
                You agree not to access (or attempt to access) the website and/or the
                materials or Services on our platform by any means other than through
                the interface that is provided by us. To obtain or attempt to obtain any
                materials, documents or information through any means not specifically
                made available through the Website is hereby expressly prohibited.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                INTELLECTUAL PROPERTY
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We shall own and retain the complete Intellectual Property rights in the
                (i) Services and grant user a non-exclusive, non-transferable, limited
                license to use the website only for the purpose of receiving the
                Services and/or utilizing the deliverables in accordance with the terms
                herein as well as other terms on the Website.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                LINKS
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                The website may provide links to other websites or resources. Because we
                have no control over such websites and resources, you acknowledge and
                agree that we are not responsible for the availability of such external
                sites and resources, and do not endorse and are not responsible or
                liable for any content, advertising, products or other materials on or
                available from such websites or resources. You further acknowledge and
                agree that we will not be liable, directly or indirectly, for any damage
                or loss caused or alleged to be caused by or in connection with the use
                or reliance on any such content, goods or services available on or
                through any such website or resource.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                USER PROHIBITIONS
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Unauthorized access to registered areas of this Site by non-registered
                users is strictly prohibited. If you have a user password allowing
                access to a non-public area of this Site, you may not disclose or share
                your password with any third party(ies) or use your password for any
                unauthorized purpose.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                DISCLAIMERS, LIMITATION OF LIABILITY AND INDEMNIFICATION
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                You expressly understand and agree that, to the maximum extent permitted
                by applicable law: <br />
                <br />
                This Site, the services and other materials are provided "AS-IS",
                without warranty of any kind, express, implied, statutory or otherwise,
                including the implied warranties of title, non-infringement,
                merchantability or fitness for a particular purpose and that we assume
                no responsibility for your (in) ability to (or any costs or fees
                associated with your (in) ability to obtain access to website nor do we
                assume any liability for the failure to store or maintain any user
                communications or personal settings.
                <br />
                <br />
                PROVIDED that the User agrees, accepts and understands that all Product
                &amp; Services work is carried out on a good-faith basis and as per
                specific understanding / agreement by and between us and the party
                concerned.
                <br />
                <br />
                The website makes no warranty that <br />
                <br />
                1. Your requirements will be met or that services provided will be
                uninterrupted, timely, secure or error-free;
                <br />
                <br />
                2. Materials, information obtained and descriptions of services will be
                effective, accurate or reliable;
                <br />
                <br />
                3. Any errors or defects in the website, services or other materials
                will be corrected;
                <br />
                <br />
                4. The services availed shall be available throughout the year, or
                available at all times; or No information, whether oral or written,
                obtained by you from us or through or from our services shall create any
                warranty not expressly stated in these terms and conditions.
                <br />
                <br />
                The user understands and agrees that any material or data downloaded or
                otherwise obtained through the website, including all services related
                work is done entirely at their own discretion and risk and they will be
                solely responsible for any damage to their computer systems or loss of
                data that results from the download of such material, data or service.
                The website accepts no liability for any errors or omissions, with
                respect to any information provided to you whether on behalf of itself
                or third parties. In no event shall we would be liable to you or any
                third party for any indirect, consequential, exemplary, incidental,
                special or punitive damages, including lost profit damages arising from
                your use of website or its services even if we have been advised of the
                possibility of such damages.
                <br />
                <br />
                To the maximum extent permitted by applicable law, we will have no
                liability related to user content arising under intellectual property
                rights, libel, privacy, publicity, obscenity or other laws. The website
                also disclaims all liability with respect to the misuse, loss,
                modification or unavailability of any user content.
                <br />
                <br />
                You agree to indemnify, defend and hold harmless us including but not
                limited to our affiliate, vendors, agents, associates and employees from
                and against any and all losses, liabilities, claims, damages, demands,
                costs and expenses (including legal fees and disbursements in connection
                therewith and interest chargeable thereon) asserted against or incurred
                by us that arise out of, result from, or may be payable by virtue of,
                any breach or non-performance of any representation, warranty, covenant
                or agreement made or obligation to be performed by you pursuant to these
                terms of use, or any other action incurred by you as a result of using
                the website or the vendor services offered therein.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                JURISDICTION
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                This agreement is governed and construed in accordance with the Laws of
                Union of India. You hereby irrevocably consent to the exclusive
                jurisdiction and venue of courts in Kolkata, West Bengal, India, in all
                disputes arising out of or relating to the use of our site/services. Use
                of our site/services is unauthorized in any jurisdiction that does not
                give effect to all provisions of these terms and conditions, including
                without limitation this paragraph. You agree to indemnify and hold the
                Company, its subsidiaries, affiliates, officers, directors, employees,
                and representatives harmless from any claim, demand, or damage,
                including reasonable attorneys' fees, asserted by any third party due to
                or arising out of your use of or conduct on the site/services.
                <br />
                <br />
                The section titles and other headings in these Terms are for convenience
                only and have no legal or contractual effect. Your acceptance of these
                terms, and your use of the Site do not create a joint venture, a
                partnership, an employment, or an agency relationship with us. You may
                not assign, delegate, or transfer your rights or obligations under these
                Terms. Our failure to exercise or enforce any right or provision of
                these Terms will not operate as a waiver of such right or provision. If
                any provision of these Terms is unlawful, void or unenforceable, that
                provision is deemed severable and does not affect the validity and
                enforceability of any remaining provisions.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                <strong>Force majeure</strong>
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We shall not be responsible for delay or default in the performance due
                to contingencies beyond our control, such as (including but not limited
                to) losses caused directly or indirectly by exchange or market rulings,
                suspension of trading, fire, flood, civil commotion, earthquake, war,
                strikes, failure of the systems, failure of the internet links or
                government / regulatory action.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                REFUND AND CANCELLATION POLICY:
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                There will be absolutely no refunds and cancellations. We do not offer
                refunds on services that have already been taken. For this reason, we
                strongly recommend that before payment, you: <br />
                <br />
                1. Read all information about our products, services and support given
                to our clients.
                <br />
                <br />
                2. Read all About Us and our Team.
                <br />
                <br />
                3. Read our Terms of Use.
                <br />
                <br />
                4. Read our Privacy Policy
                <br />
                <br />
                5. Read our Disclaimers &amp; Disclosures
                <br />
                <br />
                By making a payment for services, you acknowledge that you have read and
                agree to the above No Refund and No cancellation Policy.
              </p>
              <h4
                style={{
                  fontSize: 22,
                  margin: 5,
                  fontWeight: 500,
                  fontFamily: '"Source Sans Pro", sans-serif',
                  color: "#000"
                }}
              >
                NOTICE
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                All notices, correspondences or communications shall be served in any
                one or more of the following modes of communications and such notice or
                communication shall be served at the ordinary place of residence and/or
                last known web address / residing address and / or at the ordinary
                business address of the party to this agreement such as- <br />
                <br />
                - By hand
                <br />
                <br />
                - Delivery by post
                <br />
                <br />
                - By registered post
                <br />
                <br />
                - Under certificate of posting
                <br />
                <br />
                - By email or fax
                <br />
                <br />
                - By affixing it on the door at the last known business or residential
                address.
                <br />
                <br />
                - By oral communication to the party or on the last known telephone
                number or on the recording machine of such number.
                <br />
                <br />
                - By advertising in at least one prominent daily newspaper having
                circulation in the area where the last known business or residential
                address of the party is situated.
                <br />
                <br />
                - By notice posted on the notice board of the Exchange if no address is
                known. <br />
                <br />
                Any communication sent by us to the Client shall be deemed to have been
                properly delivered or served, if such communication is returned on us as
                unclaimed / refused / undelivered, if the same was sent in any one more
                of the above modes of communication to the ordinary place of residence
                and / or last known web address /residing address and / or at the
                ordinary business address of the client.
              </p>
            </div>
          </div>
          {/* end container */}
        </section>

      </div >
      <div><Footer /> </div>
    </>
  );
}

export default TermsAndConditions;
