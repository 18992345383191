import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function WealthManagement() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
      <section className="custom-padding services" style={{padding: '120px 0', background: '#fff'}}>
          <div className="container">
            {/* title-section */}
            <div className="main-heading" style={{marginBottom: '20px'}}>
              <h2 style={{fontSize: '28px'}}>Wealth Management</h2>
              <strong style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>WMS - An Overview</strong>
            </div>
            <ul className="fund" style={{marginBottom: '20px'}}>
              <li title="Top CA firm in delhi-ncr">At a broad level, Wealth Management combines personal investment management, financial advisory and planning discipline directly for the benefit of high net worth clients.</li>
              <li title="Top CA firm in delhi-ncr">At a more narrow level, Wealth Management helps the client construct an entire investment portfolio and advises on how to prepare for the present and future financial needs</li>
            </ul>
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">At Taneja Capital, we veer towards the latter approach. Our approach to wealth management has been more of a customized and micro approach. For us, Wealth Management is all about making the customer the nucleus of the entire process. We take a 360o view of the customer assets, liabilities, income, expenses and goals and offer our solution accordingly.</p>
            <h4 style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>
              Our Approach</h4>
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">Our approach to wealth management is predicated on five distinct stages. It is a complete loop beginning with requirements analysis all the way to portfolio monitoring.</p>
            <strong style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>Requirement Analysis</strong> 
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">This stage is about understanding the client's long term and medium goals and assigning a financial value to the entire goal matrix. This is done on the basis of a very detailed and elaborate profiling of the client based on financial and behaviour parameters.</p>     
            <strong style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>Investment option advisory</strong> 
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">At this stage, the client is offered a palate of investment combinations to choose from to meet the financial goals. Such investment options are evaluated on the parameters of risk, returns, liquidity needs and tax efficiency.</p>
            <strong style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>Implementing the actual strategy</strong> 
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">This is the critical execution part of the process. Various investment options relevant to the client's goals are rigorously evaluated based on models and an appropriate solution is implemented for the client.</p>
            <strong style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>Review and rebalancing</strong> 
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">A financial plan is never a static document. It has to be stringent enough to enforce discipline but flexible enough to adapt to the changing environment. Rebalancing is all about making modifications to the investment mix based on macro and micro stimuli.</p>
            <strong style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>Monitoring and Reporting</strong> 
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">This step is about giving the client "24x7" access to the financial plan with a host of very interesting analytics and simulation capabilities thrown in. Portfolio reports are generated and discussed with the client on a periodic basis and the client also has a secure login access to the plan at any point of time.</p>
            <h4 style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>
              Our Approach</h4>
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">At Taneja Capital, the client is the nucleus of wealth management and solutions are granularly customized to the unique needs of every client. Our commitment is evidence through:</p>
            <ul className="fund" style={{marginBottom: '40px'}}>
              <li title="Top CA firm in delhi-ncr">A synthesis of client profiling and behavioural analysis to get the best understanding of the risk appetite and the risk capacity of the client.</li>
              <li title="Top CA firm in delhi-ncr">The willingness to partner the client through the entire wealth creation life cycle by balancing risk and returns at each stage.</li>
              <li title="Top CA firm in delhi-ncr">An elegant and comprehensive reporting system that combines analytical rigor with ease of navigation for the client</li>
              <li title="Top CA firm in delhi-ncr">Focus on value for money for the client to ensure that there is substantial value for the fees paid. The pricing model is also extremely transparent.</li>
              <li title="Top CA firm in delhi-ncr">Access to financial tools, simulation capabilities, in-depth analytics by combining our 1000+ man-years of market experience is made available to our clients</li>
              <li title="Top CA firm in delhi-ncr">Pre-set portfolio alerts and macro and micro triggers can be customized to your unique tracking needs</li>
            </ul>
            <h4 style={{fontSize: '21px', margin: '20px 0px 5px', color: '#3498db', textTransform: 'capitalize', fontFamily: '"Source Sans Pro", sans-serif'}}>
              Why Choose Us</h4>
            <p style={{textAlign: 'left', margin: '0 0 20px 0', width: '100%', color: '#323232'}} title="Top CA firm in delhi-ncr">Our edge is more in helping you through a very fine-tuned and granular multi-asset strategy with the best possible combination of a top-down and a bottom-up approach. Our advantage stems from:</p>
            <ul className="fund" style={{marginBottom: '40px'}}>
              <li title="Top CA firm in delhi-ncr">Proprietary wealth management PMS products that have consistently been best-in-class performers. The risk-adjusted returns speak for themselves.</li>
              <li title="Top CA firm in delhi-ncr">We combine our grass-root understanding of the wealth business with the macro insights and inputs that our research provides to create an eclectic combination.</li>
              <li title="Top CA firm in delhi-ncr">An unmatched India-centric research consisting of 1600 stocks, 25 commodities and 2000 fixed income products as well as over 30 currencies. That is a breadth of insights.</li>
              <li title="Top CA firm in delhi-ncr">Use of intelligent algorithms to organize data and match it with goals to create a compelling financial solution that is unique only to you.</li>
              <li title="Top CA firm in delhi-ncr">Our entire investment monitoring process is supported by a mix of artificial intelligence and machine learning to make the best use of big data and advanced analytics.</li>
              <li title="Top CA firm in delhi-ncr">A unique expertise covering commodities, equities, derivatives, structured products, proprietary products and bonds gives us the flexibility to react to a range of stimuli.</li>
            </ul>
          </div>
          {/* end container */}
        </section>
      </div >
      <div><Footer /> </div>
    </>
  );
}

export default WealthManagement;
