import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function MutualFund() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };

  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading">
              <h2 style={{ fontSize: 28 }}>
                Mutual Funds – Invest Right, Live Bright!
              </h2>
              <strong
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Let Us Get Started with It Right Away!
              </strong>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Taneja Capital is a mutual funds compendium that will play a pivotal
                role in your investment-centric decision making processes empowering you
                to make a multitude of comparisons of available options and meticulous
                analysis to help you gain an edge in the market. You can invest in a way
                you have always wanted whereby reaping great benefits in return!
                Convenient investments and flexible investing options always imply that
                your pocket will never have to strain. You can even pause and change a
                selected scheme in the mid-way and that's no problem with us at all!
                Just one click and you are started with the process!
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                One-Stop-Solution for All Kinds of Mutual Funds
              </h4>
              <ul className="fund">
                <li title="Top CA firm in delhi-ncr">
                  High-Performance Equity Mutual Funds
                </li>
                <li title="Top CA firm in delhi-ncr">
                  High-Performance Debt Mutual Funds
                </li>
                <li title="Top CA firm in delhi-ncr">Mutual Fund SIPS</li>
                <li title="Top CA firm in delhi-ncr">Gold Funds</li>
              </ul>
              <br />
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Garner Lucrative Returns Tomorrow from Investments Today!
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                The smart investors today are taking the wise decision to invest at
                least a small part of their overall savings into Mutual Funds so as to
                thump the over-exceeding inflation rate by taking some marginal risks.
                Taneja Capital is here to deliver the best-ever Mutual Fund Services
                which are now very much in your reach.{" "}
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Mutual Funds Multiplicity at Taneja Capital
              </h4>
              <ul className="boxed">
                <li title="Top CA firm in delhi-ncr">
                  <h5>Gold Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Liquid Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Sector Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Mid cap Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Small cap Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Large cap Funds</h5>
                </li>
              </ul>
              <ul className="boxed">
                <li title="Top CA firm in delhi-ncr">
                  <h5>Balanced Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Diversified Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Short-term Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Tax Savingv Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>International Funds</h5>
                </li>
                <li title="Top CA firm in delhi-ncr">
                  <h5>Equity-oriented Funds</h5>
                </li>
              </ul>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Why Us
              </h4>
              <div className="col-md-12">
                <ul className="fund">
                  <li title="Top CA firm in delhi-ncr">
                    We are a top rated online MF service provider company
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Flexible investment options to avail from a multitude of choices
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Advices that truly work towards generating profits{" "}
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Your money is in safe hands with our secure investment platform
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    End-to-end investment solutions{" "}
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Knowledge centre, control centre, information centre and support
                    centre
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Well researched market reports{" "}
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Resourceful fund-centric reports
                  </li>
                  <li title="Top CA firm in delhi-ncr">
                    Consolidated and integrated portfolio management solutions
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>

      </div>
      <div><Footer /> </div>
    </>
  );
}

export default MutualFund;
