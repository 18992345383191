import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-area">
      {/*Footer Upper*/}
      <div className="footer-content">
        <div className="container">
          <div className="row clearfix">
            {/*Two 4th column*/}
            <div className="col-md-5 col-sm-12 col-xs-12">
              <div className="row clearfix">
                <div className="col-lg-7 col-sm-6 col-xs-12 column">
                  <h2>Our Service </h2>
                  <div className="footer-widget links-widget">
                    <ul>
                      <li>
                        <Link to="/core-philosophies">
                          {" "}
                          Core Values Of Investing
                        </Link>
                        {/* <a href="core-philosophies.html">
                          Core Values of Investing
                        </a> */}
                      </li>
                      <li>
                        <Link to="/pms"> PMS</Link>
                        {/* <a href="pms.html">PMS</a> */}
                      </li>
                      <li>
                        <Link to="/mutual-fund"> Mutual Fund</Link>
                        {/* <a href="mutual-fund.html">Mutual Fund</a> */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-6 col-xs-12 column">
                  <div className="footer-widget links-widget">
                    <ul className="ul2">
                      <li>
                        <Link to="/trading">Trading</Link>
                        {/* <a href="trading.html">Trading</a> */}
                      </li>
                      <li>
                        <Link to="/wealth-management">Wealth Mangement</Link>
                        {/* <a href="wealth-management.html">Wealth Mangement</a> */}
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Footer Column*/}
              </div>
            </div>
            {/*Two 4th column End*/}
            {/*Two 4th column*/}
            <div className="col-md-7 col-sm-12 col-xs-12">
              <div className="row clearfix">
                {/*Footer Column*/}
                {/*Footer Column*/}
                <div className="col-lg-5 col-sm-6 col-xs-12 column">
                  <div className="footer-widget links-widget">
                    <h2>Company </h2>
                    <ul>
                      <li>
                        <Link to="/about-us"> About Us</Link>
                        {/* <a href="about-us.html">About Us </a> */}
                      </li>
                      <li>
                        <Link to="/career">Careers</Link>
                        {/* <a href="career.html">Careers</a> */}
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">
                          {" "}
                          Terms and Conditions
                        </Link>
                        {/* <a href="terms-and-conditions.html">
                          Terms and Conditions{" "}
                        </a> */}
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        {/* <a href="privacy-policy.html">Privacy Policy</a> */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-sm-6 col-xs-12 column">
                  <div className="footer-widget about-widget">
                    <h2>Contact Us </h2>
                    <ul className="contact-info">
                      <li>
                        <span className="icon fa fa-map-marker" />
                        {/* Office No 206
                        A-13 3rd Floor, <br />
                        Kailash Colony, New Delhi, 110048{" "} */}
                        Office No 204, Deenar Bhawan 44, <br />
                        Nehru place New Delhi- 110019{" "}
                      </li>
                      <li>
                        <span className="icon fa fa-envelope-o" />{" "}
                        kanika@tanejacapital.com{" "}
                      </li>
                      <li>
                        <span className="icon fa fa-phone" />{" "}
                        <img src="assets/images/india.png" alt="India" />{" "}
                        +91-9910921339 <br /> &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;011
                        49326230
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*Two 4th column End*/}
          </div>
        </div>
      </div>
      {/*Footer Bottom*/}
      <div className="footer-copyright">
        <div className="auto-container clearfix">
          {/*Copyright*/}
          <div className="copyright text-center">
            ©Copyright 2018 Taneja Capital, All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
