import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';

function AboutUS() {

  // const [collapsed, setCollapsed] = useState(false);

  // const handleToggleSidebar = () => {
  //   setCollapsed(!collapsed);
  // };


  return (
    <>
      <div><Header /> </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading">
              {" "}
              <strong style={{ fontSize: 20, color: "#000b12", fontWeight: "normal" }}>
                About Taneja Capital
              </strong>
              <h2 style={{ fontSize: 28 }}>Built to Last Foundation</h2>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We started over one decades ago. A lot has changed ever since, but what
                has not are our fundamental principles, grounded in our core philosophy
                of improving financial security of our customers - in a way which is
                absolutely ethical and relatively superior than the industry standards,
                and also in a way that the long term interests of the company are taken
                care of automatically while believing and doing so.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Personalized and world class, we at Taneja Capital improve the health of
                your wealth. As a process-led and professionally managed company, we
                consistently deliver superior returns to our investors. With the
                knowledge-is-power approach, we hold our pulse on the market.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Our Vision
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We will be the most trusted, most knowledgeable, most understanding and
                most concerned provider of value added and customer centric financial
                services in our strategically chosen class and also mass market.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Our Mission
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We commit ourselves both in thought and action to raise ourselves in the
                eyes of our true boss - the investors from being a mere transaction
                broker to a true family financial doctor and help them to protect and
                improve their financial health.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                We further resolve not to sell daru (gambling) in the bottle of dawa
                (investment) and will dare to tell them the difference between the two
                even if it results into low revenue in the short term.
              </p>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                {" "}
                We shall invest most of our time, energy and resources to reduce gaps at
                each touch points with our existing investors, and shall see our growth
                in their growth. Let us believe that quantity follows quality.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Core Purpose
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Like a Doctor whose primary duty is to save and improve the life of his
                patient, the primary duty of our company, as an investment and financial
                advisor, should be to help our customers protect and create wealth. The
                profit of the organization should be seen as the secondary objective and
                as the resultant of the primary duty.
              </p>
              <h4
                style={{
                  fontSize: 21,
                  margin: "20px 0px 5px",
                  color: "#3498db",
                  textTransform: "capitalize",
                  fontFamily: '"Source Sans Pro", sans-serif'
                }}
              >
                Quality Policy
              </h4>
              <p
                style={{ textAlign: "left", margin: "0 0 20px 0", width: "100%" }}
                title="Top CA firm in delhi-ncr"
              >
                Taneja Capital is committed to implementing appropriate quality
                management system to ensure satisfaction of the client (core purpose)
                and other interested parties by ensuring the planning and delivery of
                consistently high level of service as per the predetermined high
                standards of systems, processes, policies, procedures and behaviors
                required for each of our financial products throughout the extensive
                area of operation. We all share the responsibility to ensure continual
                improvement and establish long term relationship with each concerned
                party.
              </p>
            </div>
          </div>
          {/* end container */}
        </section>

      </div >
      <div><Footer /> </div>
    </>
  );
}

export default AboutUS;
